export default {
  data() {
    return {
      valid: true,
      isSubmit: false,
      initFormData: null,
      checkChangesBeforeLeave: false
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.fullPath !== 'login' && this.checkChangesBeforeLeave && this.checkFormDataChange()) {
      window.confirm({
        title: 'Вы внесли изменения, но не сохранили их!',
        text: 'Уверенны, что хотите покинуть форму без сохранения данных?',
        confirmCallback: () => { next() },
        confirmText: 'Да',
        cancelText: 'Нет'
      })
    } else {
      next()
    }
  },

  methods: {
    isRequiredField({ value, field, errorText }) {
      return (value && value !== '') || this.getRequiredFieldError(field) || errorText
    },

    getRequiredFieldError(field) {
      if (!field) {
        return null
      }

      return `Поле ${field} не может быть пустым`
    },

    async onSubmitForm() {
      await this.$refs.form.validate()

      if (!this.valid) {
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true
      window.isLoading(true)

      const submitPromise = this.onSubmit()

      if (submitPromise) {
        submitPromise
          .finally(() => {
            window.isLoading(false)
          })
      }
    },

    successSubmit({ message, routeName }) {
      this.checkChangesBeforeLeave = false

      if (message) {
        window.snackbar(message, 'info')
      }

      if (routeName) {
        this.$router.push({ name: routeName })
      }
    },

    afterSubmit() {
      this.isSubmit = false
      window.isLoading(false)
    },

    cancelForm({ routeName }) {
      this.checkChangesBeforeLeave = false

      if (routeName) {
        this.$router.push({ name: routeName })
      }
    },

    setInitFormData(data) {
      this.checkChangesBeforeLeave = true
      this.initFormData = JSON.parse(JSON.stringify(data))
    },

    checkFormDataChange() {
      return Object.keys(this.getDiff(this.form)).length
    },

    getDiff(data) {
      const findChanges = (original, updated) => {
        let changes = {}
        const keys = new Set([...Object.keys(original), ...Object.keys(updated)]);

        for (let key of keys) {
          const originalValue = original[key]
          const updatedValue = updated[key]

          if (!(key in original)) {
            changes[key] = updatedValue
            continue
          }

          if (!(key in updated)) {
            continue
          }

          if (
            typeof originalValue !== 'object' ||
              originalValue === null ||
              typeof updatedValue !== 'object' ||
              updatedValue === null
          ) {
            if (originalValue !== updatedValue) {
              changes[key] = updatedValue
            }
          } else {
            const nestedChanges = findChanges(originalValue, updatedValue)
            if (Object.keys(nestedChanges).length > 0) {
              changes[key] = nestedChanges
            }
          }
        }

        return changes
      }

      return findChanges(this.initFormData, data)
    }
  }
}
